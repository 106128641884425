@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;400;600;900&family=Courier+Prime:wght@400;700&display=swap');
body {
    /* margin: 25px; */
    padding: 0;
    margin: 0;
    font-family: "Archivo";
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}