@import './vars.scss';
.wallet-container {
    position: fixed;
    top: 5px;
    right: 5px;
    display: none;
    button {
        font-family: "Archivo";
        border: 0;
        height: 35px;
        border: 1px solid $dark;
        line-height: 35px;
        padding: 0 15px;
        background-color: transparent;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        margin: 15px;
        display: flex;
    }
}