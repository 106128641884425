        @import './vars.scss';
        .collections-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            min-height: 100vh;
            // padding: 75px 0 0;
        }
        
        .collections-artist {
            width: 90%;
            margin: 0 5%;
        }
        
        .collections-nfts {
            display: flex;
            flex-wrap: wrap;
            margin-top: 50px;
        }
        
        .collection-wrapper {
            display: flex;
            flex-wrap: wrap;
            .collection-title {
                width: 100%;
                font-family: "archivo";
            }
            .collection-preview {
                width: 30%;
                @media only screen and (max-width: $tablet) {
                    width: 100%;
                }
            }
            .collection-desc {
                width: 70%;
                padding: 0 15px;
                box-sizing: border-box;
                h2 {
                    margin-top: 0;
                }
                @media only screen and (max-width: $tablet) {
                    width: 100%;
                    padding: 0;
                }
                p {
                    margin-top: 0;
                    @media only screen and (max-width: $tablet) {
                        padding: 25px 0;
                    }
                }
            }
        }
        
        .collections-nft {
            display: flex;
            flex-wrap: wrap;
            width: 50%;
            margin-bottom: 25px;
            h3 {
                margin-top: 0;
            }
            .collections-image {
                width: 40%;
            }
            .collections-desc {
                padding: 0 15px;
                box-sizing: border-box;
                width: 60%;
                @media only screen and (max-width: $tablet) {
                    padding: 0px;
                }
            }
        }
        
        @media only screen and (max-width:769px) {
            .collections-nft {
                display: flex;
                flex-wrap: wrap;
                width: 48%;
                margin: 1%;
                .collections-image {
                    width: 100%;
                }
                .collections-desc {
                    width: 100%;
                }
            }
        }
        
        @media only screen and (max-width:400px) {
            .collections-nfts {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }
            .collections-nft {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                margin: auto 0;
                .collections-image {
                    width: 100%;
                }
                .collections-desc {
                    width: 100%;
                }
            }
        }
        
        .lds-dual-ring {
            display: inline-block;
            width: 24px;
            height: 24px;
        }
        
        .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 20px;
            height: 20px;
            margin: 7px 4px;
            border-radius: 50%;
            border: 3px solid #fff;
            border-color: #fff transparent #fff transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
        
        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }