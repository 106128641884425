@import './vars.scss';
.footer-container {
    max-width: 800px;
    width: 100%;
     position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: center;
    padding: 5px 2% 0;
    // height: 50px;
    line-height: 50px;
    margin-top: 50px;
    box-sizing: border-box;
    // @media screen and (min-width: $tablet) {
    //     width: 40%;
    //     right: 0;
    //     left: auto;
    // }
    a {
        color: inherit;
        display: block;
        line-height: 45px;
        padding: 0 20px;
        text-decoration: none;
        width: 25%;
        @media screen and (max-width: $mobile) {
            font-size: 13px;
            width: 100%;
            text-align: center;
        }
        @media screen and (max-width: $tablet) {
            font-size: 14px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}