@import './vars.scss';
h1,
h2,
h3,
h4,
h5 {
    font-family: 'Archivo';
}

h2 {
    font-weight: 200;
    font-size: 2em;
}

h3 {
    font-weight: 200;
    font-style: italic;
}

h5 {
    font-weight: 200;
    font-size: 16px;
    padding: 0;
    margin: 0;
}

p {
    font-family: 'Courier Prime';
}

a {
    color: inherit;
}

.pushverzierung {
    position: absolute;
    width: 250px;
    height: 250px;
    border: 4px solid $dark;
    right: 50px;
    svg {
        fill: transparent;
        stroke: $dark;
    }
}

.ref-link,
button {
    margin: 50px 0;
    font-family: 'Archivo';
    color: $dark;
    background-color: transparent;
    font-weight: 200;
    border: 2px solid $dark;
    text-align: left;
    padding: 5px;
    margin-top: 15px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    padding-right: 45px;
    transition: all 0.5s;
    &:hover {
        background-color: $dark;
        color: $light;
        transition: all 0.5s;
        //  border: 2px solid $light;
        svg {
            fill: $light;
            transition: fill 0.5s;
        }
    }
    svg {
        fill: $dark;
        position: absolute;
        top: -1px;
        right: 0px;
        width: 25px;
        height: 25px;
        transform: rotate(180deg);
        margin-left: 10px;
        transition: fill 0.5s;
    }
    h5 {
        font-size: 18px;
        display: inline;
    }
}

.processing-link {
    margin: 50px 0;
    font-family: 'Archivo';
    color: $dark;
    background-color: $dark;
    color: $light;
    font-size: 18px;
    font-weight: 200;
    padding: 5px;
    margin-top: 15px;
    border: 2px solid $dark;
    display: inline-block;
    text-decoration: none;
    position: relative;
    padding-right: 45px;
    h5 {
        font-size: 18px;
        display: inline;
    }
    .lds-dual-ring {
        fill: $dark;
        position: absolute;
        top: 9px;
        right: 0px;
        width: 25px;
        height: 25px;
        transform: rotate(180deg);
        margin-left: 10px;
    }
    &:hover {
        // opacity: 0.9;
        // transition: opacity .5s ease;
    }
}

.main-container {
    width: 100%;
    min-height: 100vh;
    background: $light;
    margin: 0;
    padding: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
    @media screen and (min-width: 800px) {
        flex-direction: column;
    }
}

canvas {
    width: 100%;
    height: 100%!important;
}

.info-container {
    justify-content: center;
    position: relative;
    right: 0;
    top: 0;
    padding: 15px;
    margin-top: 0;
    background-color: white;
    z-index: 99;
    height: auto;
    overflow-y: scroll;
    height: 100vh;
    box-sizing: border-box;
    @media screen and (min-width: 800px) {
        margin-top: 45px;
        position: absolute;
        height: calc(100vh - 45px) !important;
    }
}

@media only screen and (max-width:769px) {
    .main-container {
        flex-wrap: wrap;
        height: auto;
    }
    .info-container {
        width: 100%;
    }
    .video-container {
        width: 100%;
    }
}