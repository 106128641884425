@import './vars.scss';
.collectionsview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 75px 0 0 0;
    width: 80%;
    margin: 0 auto;
}

.collections-artist {
    width: 90%;
    margin: 0 5%;
}

.collectionsview-artist {
    h1 {
        font-family: "Archivo";
        font-weight: 100;
    }
    .main-ref {
        width: 45%;
        @media only screen and (max-width: $tablet) {
            width: 100%;
        }
    }
}

.collectionsoverview-nfts {
    display: flex;
    // padding: 25px;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    // border-width: 4px;
    // border-style: solid;
    .collections-desc {
        padding: 0 15px;
        box-sizing: border-box;
    }
}

.collectionsoverview-nfts {
    .collectionsoverview-image {
        width: 45%;
    }
    .collectionsoverview-desc {
        padding: 0 15px;
        box-sizing: border-box;
        width: 55%;
    }
}

.collections-nft {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    text-decoration: none;
    color: inherit;
    h1 {
        margin-top: 0;
        font-family: 'Archivo';
        font-weight: 600;
        font-size: 26px;
    }
    h3 {
        // margin-top: 0;
        // font-family: 'Archivo';
        // font-weight: 600;
        // font-size: 18px;
    }
    p {
        // font-family: 'Courier Prime';
        font-weight: 200;
    }
}

.colectionsoverview-link {
    background: rgba(0, 0, 0, .9);
    color: white;
    padding: 10px 15px;
    text-decoration: none;
    // opacity: 0.7;
    transition: opacity .5s ease;
    display: block;
    font-size: 20px;
    line-height: 21px;
    font-weight: 700;
    &:hover {
        // opacity: 0.9;
        transition: opacity .5s ease;
    }
}

@media only screen and (max-width:$tablet) {
    .collections-nfts {
        display: flex;
        flex-wrap: wrap;
        // width: 48%;
        // margin: 1%;
    }
    .collections-nft {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .collectionsoverview-image {
            width: 100%;
        }
        .collectionsoverview-desc {
            width: 100%;
        }
    }
}

@media only screen and (max-width:$mobile) {
    .collections-nfts {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0%;
    }
    .collections-nft {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .collectionsoverview-image {
            width: 100%;
        }
        .collectionsoverview-desc {
            width: 100%;
            margin-top: 15px;
            padding: 0;
        }
    }
}