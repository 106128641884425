@import './vars.scss';




.artist-wrapper {
    // width: 100%;
    // position: absolute;
    // height: 100%;
    .video {
        width: 100%;
        height: 100%;
    }
}
.collection-artistoverview{
    max-width: 90%;
    margin: 0 auto;
}
.artist-box {
    position: relative;
    width: calc(50% - 50px);
    margin: 25px;
    height: 500px;
    overflow: hidden;
    background-color: $dark;
    @media screen and (max-width: $tablet) {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    h1 {
        font-family: "Archivo", sans-serif;
        font-size: 5vw;
        line-height: 0.7em;
        font-weight: 700;
        margin: 0 0 15px;
        @media screen and (max-width: $tablet) {
            font-size: 13vw;
        }
    }
    &:hover {
        .artist-desc-wrapper {
            opacity: 0;
            transition: opacity 0.5s;
        }
    }
    .artist-desc-wrapper {
        position: absolute;
        top: 0;
        opacity: 1;
        width: 100%;
        height: 50%;
        /* other styles (left, top, right, and padding) */
        color: $light;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), transparent);
        transition: opacity 0.5s;
        .artist-desc {
            position: absolute;
            left: 20px;
        }
    }
    video {
        background-color: black;
    }
    a {
        // position: absolute;
    }
}

.artists {
    display: flex;
    // font-family: 'Archivo';
    max-width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    a {
        color: inherit;
    }
}