@import './vars.scss';
.current-artist-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0;
    h5 {
        font-size: 18px;
    }
    p {
        font-size: 13px;
    }
    .artist-info {
        display: block;
        h1 {
            color: $dark;
            font-size: 30px;
            line-height: 34px;
            font-weight: 700;
            margin: 5px;
        }
    }
}

.progress {
    margin-top: 35px;
}

.collections-nft-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    // opacity: .9;
    // transition: opacity .5s ease;
    font-size: 14px;
    font-weight: 700;
    padding: 15px 5%;
    margin: 5px 0;
    text-decoration: none;
    &:hover {
        // opacity: 1;
        // transition: opacity .5s ease;
    }
}