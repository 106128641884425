@import './vars.scss';
.video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    background: $dark;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        z-index: 99;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.hider {
    width: 100%;
    height: 100%;
    &>div {
        width: 100%!important;
        height: 100%!important;
    }
}

.playing {
    position: absolute;
    z-index: 102;
    button {
        cursor: pointer;
        background-color: #000000;
        color: #ffffff;
        border: 0;
        margin: 5px;
        width: 150px;
        height: 150px;
        border-radius: 75px;
        svg {
            fill: white;
            margin-left: 15px;
        }
    }
}

.toggleMedia {
    position: absolute;
    z-index: 102;
    right: 0;
    display: none;
    bottom: 0;
    button {
        cursor: pointer;
        border: 0;
        width: 45px;
        line-height: 45px;
        padding: 0;
        position: relative;
        opacity: 0.8;
        height: 45px;
        color: $light;
        background-color: $dark;
        &:hover {
            color: $dark;
            background-color: $light;
            svg {
                fill: black;
            }
        }
        svg {
            fill: white;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.threed {
    width: 100%;
    height: 100%;
}

.controls {
    position: absolute;
    z-index: 101;
    // display: none;
    bottom: 0;
    right: 0;
    button {
        cursor: pointer;
        border: 0;
        // display: none;
        width: 45px;
        line-height: 45px;
        padding: 0;
        position: relative;
        opacity: 0.8;
        height: 45px;
        &:hover {
            color: $dark;
            background-color: $light;
            svg {
                fill: black;
            }
        }
        svg {
            fill: white;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.video-container:hover .controls {
    display: block;
}

.main-video-container {
    position: relative;
    @media screen and (max-width: $tablet) {
        margin-top: 45px;
    }
}

iframe {
    height: 186%;
    left: -44%;
    position: absolute;
    top: -43%;
    width: 186%;
    z-index: 90;
}