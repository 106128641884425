@import './vars.scss';
.about-container {
    // position: fixed;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    // display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.about-info {
    width: 50%;
    margin: 100px auto;
    // display: flex;
    // flex-wrap: wrap;
    padding: 0 0 10%;
    svg {
        width: 50%;
        margin: 0 auto;
        display: block;
    }
    @media screen and (max-width: $tablet) {
        width: 70%;
        margin: 15% auto;
    }
    // @media screen and (min-width: $desktop) {
    //     width: 60%;
    //     margin: auto 20%;
    // }
    // @media screen and (min-width: $jumbo) {
    //     width: 50%;
    //     margin: auto 25%;
    // }
    // h1 {
    //     color: inherit;
    //     font-size: 35px;
    //     @media screen and (min-width: $tablet) {
    //         font-size: 45px;
    //     }
    //     @media screen and (min-width: $desktop) {
    //         font-size: 55px;
    //     }
    //     @media screen and (min-width: $jumbo) {
    //         font-size: 65px;
    //     }
    // }
    .about-box {
        width: 100%;
        margin: 0;
        padding: 0;
        // border-bottom-right-radius: 10%;
        // background: rgba(0, 0, 0, .05);
        // display: flex;
        // justify-content: center;
        // align-items: center;
        p {
            // padding: 5% 10%;
            // font-size: 35px;
            // line-height: 37px;
            // text-indent: 30px;
            color: inherit;
            // @media screen and (min-width: $tablet) {
            //     font-size: 18px;
            //     line-height: 24px;
            // }
            // @media screen and (min-width: $desktop) {
            //     font-size: 20px;
            //     line-height: 26px;
            // }
        }
    }
}