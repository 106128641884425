@import './vars.scss';
// .main-ref {
//     width: 20%;
//     // height: 200px;
//     border: 2px solid $dark;
//     // line-height: 200px;
//     right: 0;
//     top: 0;
//     padding: 50px;
//     align-self: flex-end;
//     // position: absolute;
//     box-sizing: border-box;
//     position: relative;
//     @media screen and (max-width: $tablet) {
//         width: 100%;
//         margin-top: 50px;
//     }
// }
.ref {
    width: 50%;
    // @media screen and (min-width: $tablet) {
    //     width: 30%;
    // }
}

.current-date {
    // position: absolute;
    border: 2px solid $dark;
    // right: 50px;
    padding: 50px;
    width: 20%;
    box-sizing: border-box;
    @media screen and (max-width: $mobile) {
        width: 80%;
    }
    @media screen and (max-width: $tablet) {
        width: 80%;
    }
}

// .ref-link {
//     display: flex;
//     text-decoration: none;
//     height: 50px;
//     font-size: 14px;
//     // line-height: 50px;
//     padding: 0 0 0 15px;
//     justify-content: space-between;
//     align-items: center;
//     h5 {
//         padding: 0;
//     }
//     // p {
//     //     font-size: 20px;
//     //     line-height: 21px;
//     //     font-weight: 700;
//     //     margin: 0;
//     //     padding: 0;
//     // }
//     svg {
//         width: 50px;
//         height: 30px;
//         transform: rotate(180deg);
//         padding: 10px 0 0;
//     }
// }
// .main-ref-link {
//     display: flex;
//     text-decoration: none;
//     height: 50px;
//     line-height: 50px;
//     justify-content: space-between;
//     align-items: center;
//     p {
//         font-size: 20px;
//         line-height: 21px;
//         font-weight: 700;
//         margin: 0;
//         padding: 0;
//     }
//     svg {
//         width: 50px;
//         height: 30px;
//         transform: rotate(180deg);
//         padding: 10px 0 0;
//     }
// }
// .main-ref-link {
//     text-decoration: none;
//     height: 50px;
//     line-height: 50px;
//     // flex-wrap: wrap;
//     // display: flex;
//     // padding: 15px;
//     p {
//         font-size: 24px;
//         line-height: 21px;
//         font-weight: 700;
//         margin: 0;
//         padding: 0;
//         text-transform: uppercase;
//         // font-family: 'Archivo';
//     }
//     svg {
//         width: 50px;
//         position: absolute;
//         bottom: 0;
//         right: 0;
//         height: 30px;
//         transform: rotate(180deg);
//         padding: 10px 0 0;
//     }
// }
.current-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 100px;
    padding: 0 10%;
    // width: 80%;
    // min-height: 100vh;
    @media screen and (max-width: $mobile) {
        width: 100%;
        padding: 0 10%;
    }
    @media screen and (min-width: $tablet) {
        width: 80%;
        padding: 0 10%;
    }
    // @media screen and (min-width: $desktop) {
    //     width: 80%;
    //     padding: 0 10%;
    // }
    // @media screen and (min-width: $jumbo) {
    //     width: 80%;
    //     padding: 0 10%;
    // }
    h5 {
        // margin: 0;
        // font-weight: 100;
        // // font-family: 'Archivo';
        // padding: 0;
        // margin: 15px 0;
        // font-size: 2em;
    }
    h1 {
        // font-family: 'Archivo', sans-serif;
        font-size: 9vw;
        line-height: 0.8em;
        font-weight: 700;
        margin: 0 0 15px;
        @media screen and (max-width: $tablet) {
            font-size: 12vw;
        }
        // @media screen and (min-width: $mobile) {
        //     font-size: 45px;
        //     line-height: 48px;
        // }
        // @media screen and (min-width: $tablet) {
        //     font-size: 50px;
        //     line-height: 54px;
        // }
        // @media screen and (min-width: $desktop) {
        //     font-size: 60px;
        //     line-height: 66px;
        // }
        // @media screen and (min-width: $jumbo) {
        //     font-size: 70px;
        //     line-height: 80px;
        // }
    }
    .current-box-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .current {
        width: 80%;
        box-sizing: border-box;
        // border-bottom-right-radius: 40px;
        @media screen and (max-width: $tablet) {
            width: 100%;
        }
        a {
            text-decoration: none;
            color: inherit;
            &:hover {
                background: white;
            }
        }
        h5 {
            font-size: 20px;
            line-height: 22px;
            margin: 0;
            font-weight: 100;
            // font-family: 'Archivo';
            padding: 0;
            // opacity: .8;
            // @media screen and (min-width: $mobile) {
            //     font-size: 22px;
            //     line-height: 24px;
            // }
            // @media screen and (min-width: $tablet) {
            //     font-size: 25px;
            //     line-height: 28px;
            // }
            // @media screen and (min-width: $desktop) {
            //     font-size: 28px;
            //     line-height: 32px;
            // }
            // @media screen and (min-width: $jumbo) {
            //     font-size: 32px;
            //     line-height: 36px;
            // }
        }
    }
}